import logo from "../../assets/logo.jpeg";
import item1 from "../../assets/item1.jpg";
import item2 from "../../assets/item2.jpg";
import item3 from "../../assets/item3.jpg";
import blueStar from "../../assets/starBlue.png";
import greyStar from "../../assets/starGrey.png";
import user from "../../assets/user.png";
import { useEffect, useState } from "react";
import { Reviews } from "../Reviews/Reviews";
import { New } from "../New/New";
import { Additional } from "../Additional/Additional";
import { ColorRing } from "react-loader-spinner";
// import { useReactPWAInstall } from "react-pwa-install";

export const Main = ({
  handleClick,
  animating,
  setAnimating,
  megabyte,
  supported,
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const { supported, isInstalled } = useReactPWAInstall();

  const creative_id = localStorage.getItem("creative_id");
  const offer = localStorage.getItem("offer");
  const ad_campaign_id = localStorage.getItem("ad_campaign_id");
  const source = localStorage.getItem("source");
  const creo_id = localStorage.getItem("creo_id");
  const external_id = localStorage.getItem("external_id");
  const site_id = localStorage.getItem("site_id");
  const cost = localStorage.getItem("cost");
  const sub_id_1 = localStorage.getItem("sub_id_1");
  const id = localStorage.getItem("id");

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const linkToOffer = () => {
    setAnimating(true);
    setTimeout(() => {
      if (id === "1164 ") {
        window.location.href =
          `https://arumo.top/55qkQMqm?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1167") {
        window.location.href =
          `https://arumo.top/fGTmZcnr?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1169") {
        window.location.href =
          `https://arumo.top/BnCYP2tK?a=b` +
          (cost !== null ? `&cost=${cost}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1165") {
        window.location.href =
          `https://arumo.top/8BgfHvLC?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1244") {
        window.location.href =
          `https://arumo.top/3x9KXdLC?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1247") {
        window.location.href =
          `https://arumo.top/993gZBF5?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1249") {
        window.location.href =
          `https://arumo.top/GQdMt552?a=b` +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "");
      } else if (offer === "zBcMPrcX") {
        window.location.href =
          `https://arumo.top/zBcMPrcX?ad_campaign_id=${ad_campaign_id}` +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (offer === "4cxfntfb") {
        window.location.href =
          `https://arumo.top/4cxfntfb?ad_campaign_id=${ad_campaign_id}` +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (sub_id_1 !== null ? `&sub_id_1=${sub_id_1}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else {
        window.location.href =
          `https://arumo.top/55qkQMqm?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      }
    }, 80);
  };
  return (
    <div className="main__top">
      <main className="main">
        <div className="main__wrapper">
          <img src={logo} alt="logo" className="logo" />
          <div className="main__wrap">
            <p className="main__title">Казино</p>
            <div className="main__tags">
              <p className="main__tag">Казино</p>
              <p className="main__tag">Casino</p>
            </div>
            <p className="years">18+</p>
          </div>
          <div className="desktop__info">
            <div className="top__wrapper">
              <img src={blueStar} alt="safety" width={14} height={14} />
              <p className="top__text">Выбор редакции</p>
            </div>
            <div className="bottom__wrapper">
              <div className="greyStars__wrapper">
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
              </div>
              <p className="bottom__text">2697</p>
              <img src={user} alt="" />
            </div>
          </div>
        </div>
        <div className="app__info">
          <ul className="app__list">
            <li className="app__item">
              <div className="app__content">
                <div className="app__wrapper">
                  <p className="app__title">4.8</p>
                  <svg
                    width={14}
                    enableBackground="new 0 0 24 24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fff"
                  >
                    <g fill="none">
                      <path d="M0,0h24v24H0V0z"></path>
                      <path d="M0,0h24v24H0V0z"></path>
                    </g>
                    <path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"></path>
                  </svg>
                </div>
                <p className="app__description">Рейтинг</p>
              </div>
            </li>
            <li className="app__item">
              <div className="app__content">
                <p className="app__title">
                  <svg
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    fill="#fff"
                  >
                    <g transform="translate(21.552 22.5) rotate(180)">
                      <path
                        transform="translate(7.552 7.652)"
                        d="M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z"
                        data-name="Path 288"
                      ></path>
                      <g transform="translate(17.552 20.797) rotate(180)">
                        <path
                          d="M0,0H9.666V9.666H0Z"
                          fill="none"
                          data-name="Path 289"
                        ></path>
                        <path
                          transform="translate(-4.408 -3.203)"
                          d="M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z"
                          data-name="Path 290"
                        ></path>
                      </g>
                      <rect
                        transform="translate(4.552 5.5)"
                        width="16"
                        height="16"
                        rx="2"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        data-name="Rectangle 123"
                      ></rect>
                    </g>
                  </svg>
                </p>
                <p className="app__description">3.7MB</p>
              </div>
            </li>
            <li className="app__item">
              <div className="app__content">
                <p className="app__title">21+</p>
                <p className="app__description">Возраст</p>
              </div>
            </li>
            <li className="app__item">
              <div className="app__content">
                <p className="app__title">127544</p>
                <p className="app__description">Установили</p>
              </div>
            </li>
          </ul>
        </div>
        {animating === true ? (
          <div className="lines__wrapper">
            {megabyte === "Installing..." ? (
              <p className="megabytes">{megabyte}</p>
            ) : (
              <p className="megabytes">{megabyte} MB / 3.7 MB</p>
            )}
            <div className="greyLine">
              <div className="greenLine"></div>
            </div>
          </div>
        ) : animating === "done" ? (
          supported ? (
            <a href="/" target="_blank" className="main__button pwa__link">
              Играть
            </a>
          ) : (
            <button
              type="button"
              className="main__button"
              onClick={linkToOffer}
            >
              Играть
            </button>
          )
        ) : (
          <button
            type="button"
            className="main__button"
            onClick={handleClick}
            disabled={isLoading}
          >
            {isLoading ? (
              <ColorRing
                visible={true}
                height="27"
                width="27"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"]}
              />
            ) : (
              "Установить"
            )}
            {/* {supported() && !isInstalled() ? "Install" : "Play"} */}
          </button>
        )}
        <div className="main__thumb">
          <img
            src={item1}
            alt="app view"
            height={210}
            className="main__images"
          />
          <img
            src={item2}
            alt="app view"
            height={210}
            className="main__images"
          />
          <img
            src={item3}
            alt="app view"
            height={210}
            className="main__images"
          />
        </div>
        <p className="main__about">Об этом приложении</p>
        <p className="main__item--one">
          Казино - это суперприложение, в котором собраны самые разные игры и
          новинки в мире казино!
        </p>
        <ul
          className={
            showDescription ? "main__list" : "main__list main__list--blur"
          }
        >
          <li className="main__item">
            Самое надежное и легальное Казино Украины 2024 года!
          </li>
          {showDescription && (
            <>
              <li className="main__item">
                Моментальный ввод и вывод средств, без комиссии
              </li>
              <li className="main__item">
                Минимальный депозит всего лишь 50 грн
              </li>
              <li className="main__item">
                Все самые любимые слоты в одном месте
              </li>
              <li className="main__item">
                Частое проведение турниров и лотерей
              </li>
              <li className="main__item">Круглосуточная техподдержка</li>
              <li className="main__item" style={{ marginBottom: "20px" }}>
                Лучший проект среди всех казино Украины! В новое Казино играй -
                легкие деньги получай!
              </li>

              {/* <p
                className="main__item"
                style={{ marginLeft: "-15px", marginBottom: "20px" }}
              >
                В новое Казино играй - легкие деньги получай!
              </p> */}
              <p className="main__item" style={{ marginLeft: "-15px" }}>
                Для новых игроков предусмотрен БОНУС 50.000 грн!
              </p>
              <li className="main__item">
                Бесплатное участие в розыгрыше 1 000 000 грн!
              </li>
              <li className="main__item">
                150 FS (Free Spins) - прокрутка колеса фортуны
              </li>
              <li className="main__item">
                Начисление премиальных баллов игрокам для оплаты различных услуг
              </li>
              <li className="main__item">
                Бонус на первый депозит 150% - действует 30 дней после
                регистрации
              </li>
              {/* <li className="main__item">
                100% first deposit bonus - valid 30 days after registration
              </li> */}
            </>
          )}
        </ul>
        <button
          type="button"
          className="show__button"
          onClick={() => {
            setShowDescription((prevState) => !prevState);
          }}
        >
          {showDescription ? "Меньше" : "Больше..."}
        </button>
        <div className="line"></div>
        <Reviews />
        <div className="line"></div>
        <New />
        <div className="line"></div>
        <Additional />
        <div className="line"></div>
      </main>
    </div>
  );
};
